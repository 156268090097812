import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss']
})
export class InvoicePrintComponent implements OnInit {
  invoice_id: any;
  individual_invoice: any;
  words_amount: any;
  invoice_amount: any;

  constructor(private tripService: TripService, route: ActivatedRoute, private printservice: PrintServiceService) {
    this.invoice_id = route.snapshot.params['invoice_id'];
    console.log(this.invoice_id);
  }

  ngOnInit(): void {
    this.tripService.getInvoiceDetails(this.invoice_id).subscribe((res: any) => {
      console.log(res);
      this.words_amount = res.words_amount;
      if (res.invoice[0])
        this.invoice_amount = res.invoice[0].invoice_amount;
      else
        this.invoice_amount = 0;
      let individual_invoice = res.invoice;
      this.individual_invoice = individual_invoice.filter(element => element.is_show === 1);
      console.log(this.individual_invoice);

      var file_name = "Invoice " + this.invoice_id
      this.printservice.onDataReady(file_name);
    });

  }

}
