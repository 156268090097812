import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RandomGuard } from './guards/random.guard';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './guards/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { ToastrModule } from 'ngx-toastr';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { HotkeyModule } from 'angular2-hotkeys';

import { BarcodeGeneratorComponent } from './print-layout/barcode-generator/barcode-generator.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { RegisterComponent } from './register/register.component';

import { SocialLoginModule } from 'angularx-social-login';
import { WaybillModule } from './waybill/waybill.module';
import { WaybillPrintComponent } from './print-layout/waybill-print/waybill-print.component';
import { ChecklistPrintComponent } from './print-layout/checklist-print/checklist-print.component';
import { ConfirmBoxDialogComponent } from './confirm-box/confirm-box-dialog/confirm-box-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WaybillsPrintComponent } from './print-layout/waybills-print/waybills-print.component';
import { ManifestoPrintComponent } from './print-layout/manifesto-print/manifesto-print.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'environments/environment';
import { ConnectionServiceModule } from 'ng-connection-service';
import { InvoicePrintComponent } from './print-layout/invoice-print/invoice-print.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { VoucherPrintComponent } from './print-layout/voucher-print/voucher-print.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './_helpers/custom.date.adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const config: SocketIoConfig = { url: environment.ws_url, options: {} };

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  imports: [
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),

    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SocketIoModule.forRoot(config),
    ConnectionServiceModule,
    // HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatIconModule,
    NgxQRCodeModule,
    NgxBarcode6Module,
    MatDialogModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SocialLoginModule,
    WaybillModule,
    Ng2TelInputModule,
    HotkeyModule.forRoot(),
    MatStepperModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthComponent,
    PasswordResetComponent,
    PrintLayoutComponent,
    LoaderComponent,
    BarcodeGeneratorComponent,
    RegisterComponent,
    WaybillPrintComponent,
    ChecklistPrintComponent,
    ConfirmBoxDialogComponent,
    WaybillsPrintComponent,
    ManifestoPrintComponent,
    InvoicePrintComponent,
    VoucherPrintComponent,
  ],
  providers: [
    AuthModule,
    AuthGuard,
    RandomGuard,
    LoaderService,

    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmBoxDialogComponent]

})
export class AppModule { }