import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthserviceService } from 'app/auth/authservice.service';
import { DialogService } from 'app/services/dialog.service';
import { environment } from 'environments/environment';
import { ConnectionService } from 'ng-connection-service';
import { GlobalConstants } from 'app/common/global-constants';
import { MenuService } from 'app/services/menu.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    sidebar_show: boolean;
    username: string;
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    user: any;
    private sidebarVisible: boolean;
    url = environment.url;
    s3url = environment.s3url;
    toggled: boolean = true;
    quick_menus: any;
    isConnected: boolean = true;
    show_quick: boolean;
    menuItems: any;
    added_qa_menu: any;
    new_qa_menu: any;
    side_menu: any;

    constructor(location: Location, private element: ElementRef, private router: Router, private authservice: AuthserviceService, private dialog: DialogService, private connectionService: ConnectionService, private menuSrvice: MenuService) {
        this.location = location;
        console.log(this.location);
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.getUserMenu();
        this.show_quick = false;
        this.getQuickAccessMenu();
        // this.sidebar_show = true;
        this.username = localStorage.getItem('username');
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe(() => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });

        this.getUser();

        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            console.log(this.isConnected);
        })
    }

    getUser() {
        this.authservice.getUser().subscribe(res => {
            this.user = res.user;
        })
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;
        }
    }

    logout() {
        // this.sidebarClose();
        var dialogTitle = "Do you want to logout";
        var dialogText = "Are you sure ?";
        this.dialog.openDialogConfirm(dialogTitle, dialogText).afterClosed().subscribe(res => {
            if (res) {
                this.authservice.logout().subscribe(success => {
                    if (success) {
                        this.router.navigate((['/signin']));
                    } else {
                        this.router.navigate(['/signin']);
                    }
                });
            }
        })
    }

    getTitle() {
        return environment.appVersion
        //   var titlee = this.location.prepareExternalUrl(this.location.path());
        //   if(titlee.charAt(0) === '#'){
        //       titlee = titlee.slice( 1 );
        //   }

        //   for(var item = 0; item < this.listTitles.length; item++){
        //       if(this.listTitles[item].path === titlee){
        //           return this.listTitles[item].title;
        //       }
        //   }
        //   return 'Dashboard';
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    goBack() {
        window.history.back();

    }
    toggle() {
        if (this.toggled) {
            this.close();
        } else {
            this.open();
        }
    }
    open() {
        let panel = $(window).width() - 230 + "px";
        document.getElementById("sidebar").style.width = "230px";
        document.getElementById("main-panel").style.width = panel;
        this.toggled = true;
    }
    close() {
        document.getElementById("sidebar").style.width = "0px";
        document.getElementById("main-panel").style.width = "100%";
        this.toggled = false;
    }
    //   onClickExpand() {
    //       this.sidebar_show = !this.sidebar_show;
    //   }


    getUserMenu() {
        this.menuSrvice.getRoleMenuItems().subscribe(res => {
            this.side_menu = res.side_menu;
            this.menuItems = res.side_menu;
            console.log(this.menuItems);
            const arr = [];
            var arr1 = [];
            this.menuItems.filter(x => {
                x.childs.map(y => {
                    arr.push('/' + y.sub_menu_url)
                })
            })

            arr1 = arr.concat(GlobalConstants.path_list);
            GlobalConstants.path_list = arr1;
            this.setQuickAccessMenu()
        })
    }

    /*
    * get quick menu
    */
    getQuickAccessMenu() {
        this.menuSrvice.getQuickAccessMenu().subscribe((res: any) => {
            console.log(res);
            this.quick_menus = res.data;
        });
    }

    setQuickAccessMenu() {
        // this.menuItems = this.side_menu;
        let quick_menu_ids = [];

        this.quick_menus.forEach(element => {
            quick_menu_ids.push(element.sub_menu_id)
        })
        
        let added_menu = [];
        let new_menu = [];

        this.menuItems.forEach(element => {
            let element1 = element;

            var new_childs = element1.childs.filter(
                function (i) {
                    return !quick_menu_ids.includes(i.sub_menu_id);
                }
            );

            if (new_childs.length) {
                new_menu.push({ menuname: element.menu_name, childs: new_childs })
            }

            var added_childs = element.childs.filter(function (item) {
                return quick_menu_ids.indexOf(item.sub_menu_id) !== -1;
            });

            if (added_childs.length) {
                added_menu.push({ menuname: element.menu_name, childs: added_childs })
            }
        });
        
        console.log(added_menu);
        console.log(new_menu);
        this.added_qa_menu = added_menu;
        this.new_qa_menu = new_menu;
    }

    openQuickAccessMenu() {
        console.log("openQuickAccessMenu");
        if (this.show_quick) {
            this.show_quick = false;
        } else {
            this.show_quick = true;
        }

    }

    updateMenu(menu, status) {
        menu.status = status;
        console.log(menu);
        console.log(this.quick_menus);
        this.menuSrvice.updateQuickAccess(menu).subscribe((res: any) => {
            console.log(res);
            if (status) {
                this.quick_menus.push(menu);
            } else {
                this.quick_menus = this.quick_menus.filter(element => element.sub_menu_id !== menu.sub_menu_id);
            }
            console.log(this.quick_menus);
            this.setQuickAccessMenu()
        });
    }


}
