import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import * as io from 'socket.io-client';
import { ChatService } from './services/chat.service';
import Echo from 'laravel-echo';
import { Observable, Observer } from 'rxjs';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { config } from 'process';
import {ConnectionService} from 'ng-connection-service';  
import { GlobalConstants } from './common/global-constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // private socket: any;
  public data: any;
  // echo: Echo;
  observer: Observer<number>;
  // socket: SocketIOClient.Socket;
  messages: any[];
  // isConnected: boolean;


  constructor() {
    // Connect Socket with server URL
    // this.socket = io(environment.ws_url);
    // console.log(this.socket);
  }

  // createObservable(): Observable<number> {
  //   return new Observable<number>(observer => {
  //     this.observer = observer;
  //   });
  // }

  ngOnInit(): void {
    // this.socket.on('laravel_database_chat', data => {
    //   this.data = data;
    //   console.log(this.data);
    // });


    // this.echo = new Echo({
    //   broadcaster: 'socket.io',
    //   host: window.location.hostname + ':6001',
    // });

    // this.echo.connector.socket.on('connect', function () {
    //   console.log('CONNECTED');
    // });

    // this.echo.connector.socket.on('reconnecting', function () {
    //   console.log('CONNECTING');
    // });

    // this.echo.connector.socket.on('disconnect', function () {
    //   console.log('DISCONNECTED');
    // });

    // this.echo.channel('test-channel')
    //   .listen('.test', (data) => {
    //     console.log(data);
    //   });
  }

}
