<div class="modal-content">

    <div class="modal-header bg-info d-block">
        <button type="button" class="close float-right" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-light text-center" id="invoicemodal">Invoice
        </h5>
    </div>

    <div class="modal-body">

        <div class="row">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                <img class="image" src="../../../assets/img/invoice-1.jpg">
            </div>

            <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                <h6 class="invhead"><b>INVOICE
                        فاتورة <br>TNR: 220003481700002</b></h6>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4">
                <h6>P.O Box 1869, Manama<br>Kingdom of Bahrain <br>Tel.+97317483473<br>Mob
                    +97335302143<br>goldenelitebah@gmail.com</h6>
            </div>

        </div>

        <div class="card cardborder">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Customer Name:</th>
                        <td><span></span></td>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Invoice Date:</th>
                        <td><span></span></td>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Address:</th>
                        <td><span></span></td>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Invoice No:</th>
                        <td><span></span></td>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Job Card No:</th>
                        <td><span>GLD-(SL NO)</span></td>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>TRN:</th>
                        <td><span>(Customer vat rg no)</span></td>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Master No:</th>
                        <td><span>GLD-(TRIP NO)</span></td>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">

                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <th>Mode No:</th>
                        <td><span>(Road,air,sea)</span></td>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="card cardborder">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <h5 style="color: blue;">SERVICES DETAILS</h5>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <h5 style="color: blue;">ملخص الخدمة</h5>
                            </div>
                        </div>
                        <table class="table1">
                            <thead>
                                <tr>
                                    <th>Ref No:</th>
                                    <td><span>(Way bill no)</span></td>
                                    <th></th>
                                    <td><span></span></td>
                                </tr>

                                <tr>
                                    <th>Sender:-</th>
                                    <td><span></span></td>

                                </tr>
                                <tr>
                                    <th>No of Pkg :-</th>
                                    <td><span></span></td>

                                </tr>
                                <tr>
                                    <th>G weight (kgs):-</th>
                                    <td><span></span></td>

                                </tr>
                                <tr>
                                    <th> narration :-</th>
                                    <td><span></span></td>

                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-8 col-md-8 col-lg-8">
                <table class="table table-bordered table-responsive">
                    <thead style="background-color: lightgrey;">
                        <tr>
                            <th class="nowrap">Sl No</th>
                            <th>Discription of Goods</th>
                            <th>Qty</th>
                            <th class="nowrap">Rate/Unit</th>
                            <th>Vat 5%</th>
                            <th>Amount bhd</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoice of individual_invoice;index as i">
                            <td>{{i+1}}</td>
                            <td>{{invoice?.item_name}}</td>
                            <td style="text-align: right;">{{invoice?.quantity}}</td>
                            <td style="text-align: right;">{{invoice?.rate}}</td>
                            <td>0</td>
                            <td style="text-align: right;">{{invoice?.quantity * invoice?.rate}}</td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <h4 style="white-space: nowrap;">TOTAL</h4>
                            </td>
                            <td class="h4" style="text-align: right;">{{invoice_amount}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <span>
                    <h4><b>E & O.E</b></h4>
                    <h6><b>1-</b> Cheque should be drawn in the name of GOLDE ELITE LOGISTICS A/C Payee only.Normal
                        credit
                        terms allow maximam period of 7 days from date of invoice<br>
                        <b>2-</b> The invoice amount would be
                        considered as correct unless intimated in writing with in 7 days, from the data of
                        invoice.
                        <br>
                        <b>3-</b> Official recipt will be issued upon payment <br><b>Thank you for choosing our services
                            and looking
                            forward to pleasur of seeing you in future</b>
                    </h6>
                    <p>البحرين <br>B A H R A I N <br><b>2030</b></p>
                </span>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <table class="table1">
                            <thead>

                                <tr>
                                    <th>Amount in Word BHD :</th>
                                    <td><span>{{words_amount}}</span></td>

                                </tr>

                                <tr>
                                    <th>Customer Signature :</th>
                                    <td><span></span></td>
                                    <th style="padding-left: 140px;">Approved by </th>
                                    <!-- <td><span>(Approved by)</span></td> -->
                                    <th style="padding-left: 180px;">For golden elite logistics</th>

                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-2 col-md-2 col-lg-2">

            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="https://images.pexels.com/photos/2199293/pexels-photo-2199293.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    style="width: 100px;">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="https://images.pexels.com/photos/3840441/pexels-photo-3840441.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    style="width: 100px;">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
                <img src="https://images.pexels.com/photos/1544372/pexels-photo-1544372.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    style="width: 100px;">
            </div>
            <div class="col-sm-1 col-md-1 col-lg-1">

            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h6 class="paragraph mt-5"> Air Cargo : Sea Cargo : Land Transport : Cargo Clearing : Import &
                    Export
                </h6>
                <h6 class="paragraph"> <b>Bahrain : Dubai : China : Qatar : Saudi Arabia : India</b></h6>
                <h5 class="paragraph"><b>IMPORTING FROM ALL OVER THE WORLD</b></h5>
                <h6 class="paragraph">Tel no:00558419383, 0553429393 Al Qusais Ind Area 1, Khan Saheb Warehouse
                    # 1A,Dubai-UAE</h6>
                <h6 class="paragraph">E-mail:goldenelitedubai@gmail.com , Web: www.goldenelitegargo.com</h6>
            </div>
        </div>
    </div>
</div>