import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

export interface Sender {
  customer_id: number,
  customer_name: string
}
export interface Receiver {
  customer_id: number,
  customer_name: string,
  customer_code: string,
  customer_code_id: number,
  customer_mobile: string,
  country_name: string
}
export interface Items {
  id: number;
  item_name: string;
}

export interface Countries {
  id: number;
  country_name: string;
}
export interface Checkpoints {
  id: number,
  check_point: string
}
interface Commodity {
  id: number,
  item_group_id: number,
  item_country: number,
  item_name: string,
  item_unit: string
}

@Component({
  selector: 'app-create-way-bill-component',
  templateUrl: './create-way-bill-component.component.html',
  styleUrls: ['./create-way-bill-component.component.scss']
})

export class CreateWayBillComponentComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() created = new EventEmitter();

  senders: Sender[] = [];
  items: Items[] = [];
  check_points: Checkpoints[] = [];
  countries: Countries[] = [];
  protected receivers: Receiver[] = [];
  receiver_details: Receiver;

  customerForm: FormGroup;
  selected: number;
  field = false;
  way_bill_id: any;
  way_bill: any;
  commodities: any;
  add_receiver = false;
  added_receiver_id: any;
  show_receiver = false;
  to_show_receiver: any;
  today: any;
  submitted: boolean;
  add_disable = false;
  item_list: any;
  commodity: any;
  selected_commodity: any;
  sender: any;
  selected_sender: any;
  sender_list: any;
  receiver: any;
  selected_receiver: any;
  receiver_list: any;
  origin: any;
  origin_details: any;
  origin_list: any;
  destination_list: any;
  destination: any;
  destination_details: any;
  code_id: number;
  waybill_date: any;
  last_waybill: any;
  addWayBillForm: FormGroup;

  // // start receivers filtering

  // /** control for the selected receivers */
  // public receiversCtrl: FormControl = new FormControl();

  // /** control for the MatSelect filter keyword */
  // public receiversFilterCtrl: FormControl = new FormControl();

  // /** list of receivers filtered by search keyword */
  // public filteredReceivers: ReplaySubject<Receiver[]> = new ReplaySubject<Receiver[]>(1);

  // @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  // /** Subject that emits when the component has been destroyed. */
  // protected _onDestroy = new Subject<void>();
  // receiver_search: any;
  country_list: any;
  country: any;
  selected_country: any;
  waybill_no: any;
  // end receivers filtering

  constructor(private formbuilder: FormBuilder, private waybillService: WaybillService, private userService: UserService, private toastr: ToastrService, private datePipe: DatePipe) { }

  ngOnInit(): void {

    this.code_id = 1001;
    var today1 = new Date();
    this.today = this.datePipe.transform(today1, 'yyyy-MM-dd');
    this.waybill_date = this.datePipe.transform(today1, 'yyyy-MM-dd');

    // this.getReceivers();
    this.getSendersNReceivers();
    this.getCheckPoints();
    this.getLastGeneratedNumber();
    this.getItems();

    this.userService.getCountries().subscribe((res: any) => {
      console.log(res);
      this.countries = res.countries;
      this.country_list = res.countries;
    });

    this.addWayBillForm = this.formbuilder.group({
      waybill_no: ['',],
      waybill_date: this.waybill_date,
      sender_id: ['', Validators.required],
      receiver_id: ['', Validators.required],
      narration: ['',],
      commodities: this.formbuilder.array([this.createNewItem()]),
      origin_check_id: [,],
      destination_check_id: [,],
      shipment_mode: '0',
      weight: [,],
      measurement_l: [,],
      measurement_w: [,],
      measurement_h: [,],
    });

    this.customerForm = this.formbuilder.group({
      customer_id: ['',],
      customer_type: ['',],
      customer_name: ['', Validators.required],
      customer_code: ['', Validators.required],
      customer_code_id: [''],
      customer_address: ['',],
      customer_mobile: ['', Validators.required],
      customer_email: ['', Validators.email],
      country_id: ['',],
      status: ['',]
    });
  }

  /**
   * Commodities
   */
  getItems() {
    this.waybillService.getItems().subscribe((res: any) => {
      console.log(res);
      this.items = res.items;
      this.item_list = res.items;
    });
  }

  // start receivers filtering
  ngAfterViewInit() {
    //   this.setInitialValue();
  }

  ngOnDestroy() {
    //   this._onDestroy.next();
    //   this._onDestroy.complete();
  }

  // /**
  //  * Sets the initial value after the filteredReceivers are loaded initially
  //  */
  // protected setInitialValue() {
  //   this.filteredReceivers
  //     .pipe(take(1), takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       // setting the compareWith property to a comparison function
  //       // triggers initializing the selection according to the initial value of
  //       // the form control (i.e. _initializeSelection())
  //       // this needs to be done after the filteredReceivers are loaded initially
  //       // and after the mat-option elements are available
  //       this.singleSelect.compareWith = (a: Receiver, b: Receiver) => a && b && a.customer_id === b.customer_id;
  //     });
  // }

  // protected filterReceivers() {
  //   if (!this.receivers) {
  //     return;
  //   }

  //   let search = this.receiversFilterCtrl.value;

  //   if (!search) {
  //     this.filteredReceivers.next(this.receivers.slice());
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //     this.receiver_search = this.receiversFilterCtrl.value;
  //   }
  //   // filter the receivers
  //   this.filteredReceivers.next(
  //     this.receivers.filter(receiver => receiver.customer_name.toLowerCase().indexOf(search) > -1)
  //   );
  // }
  // end receivers filtering

  getLastGeneratedNumber() {
    this.waybillService.getLastGeneratedNo().subscribe((res: any) => {
      console.log(res);
      this.last_waybill = res.waybill_no;
      this.waybill_no = res.waybill_no + 1;

      // this.addWayBillForm.patchValue({
      //   waybill_no: res.waybill_no+1
      // });
    })
  }

  /**
   * 
   */
  getSendersNReceivers() {
    this.userService.getSendersNReceivers().subscribe((res: any) => {
      console.log(res);
      this.senders = res.senders;
      this.receivers = res.receivers;
      // this.options = this.senders;
      // console.log(this.options);
      // console.log(this.filteredOptions);
      this.sender_list = res.senders;
      this.receiver_list = res.receivers;
      // getReceivers() {
      // this.userService.getReceivers().subscribe((res: any) => {
      // console.log(res);
      // this.receivers = res.receivers;

      // start receivers filter
      // set initial selection
      // this.receiversCtrl.setValue(this.receivers[10]);

      // load the initial receiver list
      // this.filteredReceivers.next(this.receivers.slice());

      // // listen for search field value changes
      // this.receiversFilterCtrl.valueChanges
      //   .pipe(takeUntil(this._onDestroy))
      //   .subscribe(() => {
      //     this.filterReceivers();
      //   });
      // end receivers filter
    });
  }

  /**
   * Check points details
   */
  getCheckPoints() {
    this.waybillService.getCheckPoints().subscribe((res: any) => {
      console.log(res);
      this.check_points = res.check_points;
      this.origin_list = res.check_points;
      this.destination_list = res.check_points;
    })
  }

  addSender() {
    this.customerForm.patchValue({
      customer_type: 1,
      status: 1
    });
  }

  /**
   * 
   */
  addReceiver() {
    this.show_receiver = false;
    // console.log(this.addWayBillForm.get('receiver_id').value);

    this.add_receiver = !this.add_receiver

    if (this.add_receiver) {
      this.userService.getNextReceiverId().subscribe((res: any) => {
        // console.log(res);
        this.code_id = res.customer_code_id;
      })
    }

    this.customerForm.patchValue({
      // customer_name: this.receiver_search,
      customer_name: this.addWayBillForm.get('receiver_id').value,
      customer_type: 2,
      status: 1
    });
  }

  customerCodeGeneration() {
    let str = this.customerForm.value.customer_name;
    str = str.toUpperCase();
    let acronym = '';
    let word_count = str.split(' ').length;
    if (word_count == 1)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 4), '')
    else if (word_count == 2)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
    else
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')

    if (acronym.length < 4)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
    else if (acronym.length < 4)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 4), '')

    acronym = acronym.slice(0, 4)
    this.customerForm.patchValue({
      customer_code: acronym
    })
  }

  /**
   * 
   */
  onAddCustomer() {

    this.add_disable = true;
    console.log(this.customerForm.value);

    if (this.customerForm.invalid) {
      this.toastr.info("Enter valid details");
      this.add_disable = false;
    } else {
      this.customerForm.patchValue({
        customer_code_id: this.code_id
      })

      this.userService.addCustomer(this.customerForm.value).subscribe((res: any) => {
        this.add_disable = false;
        console.log(res);

        if (res.success) {
          $('#addCustomerForm').modal('hide');
          this.toastr.success(res.message, 'Success');
          // this.getSenders();
          console.log(res.customer_id);
          this.added_receiver_id = res.customer_id;

          // Set receiver start
          this.receivers = res.receivers;
          this.receiver_list = res.receivers;

          this.customerForm.patchValue({
            customer_id:res.customer_id
          })

            // this.receiversCtrl.patchValue({
          //   customer_id: res.customer_id,
          //   country_id: this.customerForm.value.country_id,
          //   customer_address: this.customerForm.value.customer_address,
          //   customer_code: this.customerForm.value.customer_code,
          //   customer_code_id: this.customerForm.value.customer_code_id,
          //   customer_email: this.customerForm.value.customer_email,
          //   customer_mobile: this.customerForm.value.customer_mobile,
          //   customer_name: this.customerForm.value.customer_name,
          //   customer_type: this.customerForm.value.customer_type,
          //   status: 1
          // })

          // this.filterReceivers();
          // Set receiver end
          // this.userService.getReceivers().subscribe((res: any) => {
          //   console.log(res);
          //   this.receivers = res.receivers;
          //   this.show_receiver = true;
          this.add_receiver = false;
          // var new_receiver = this.receivers.filter(x => x.customer_id === this.added_receiver_id);
          //   this.receiver_details = new_receiver[0];
          this.receiver_details = this.customerForm.value;
          //   console.log(this.receiver_details);
          this.addWayBillForm.patchValue({
            //     // receiver_id: this.added_receiver_id
            receiver_id: this.receiver_details
            //   });
          });

          this.customerForm.reset();
          console.log(this.addWayBillForm.value);
        } else {
          this.toastr.warning(res.message, 'Warning');
        }
      });
      // }, _error => {
      //   this.toastr.warning("Enter valid details", 'warning');
      // });
    }
  }

  onAddNewRow() {
    this.commoditiesArray.push(this.createNewItem());
  }

  onRemoveRow(index) {
    this.commoditiesArray.removeAt(index);
  }

  get commoditiesArray() {
    return <FormArray>this.addWayBillForm.get("commodities");
  }

  createNewItem(): FormGroup {
    return this.formbuilder.group({
      item: ['', Validators.required],
      item_nos: [, [Validators.required, Validators.min(1)]],
      item_country: ['',]
    });
  }

  addItem() {
    console.log("add item button pressed");
  }
  get w() {
    return this.addWayBillForm.controls;
  }

  /**
   * 
   */
  createWayBill() {

    console.log(this.addWayBillForm.value);

    this.submitted = true;
    this.waybill_date = this.datePipe.transform(this.waybill_date, 'yyyy-MM-dd');

    this.addWayBillForm.patchValue({
      waybill_date: this.waybill_date,
      waybill_no: this.waybill_no,
      // receiver_id: receiver_id,
    });

    console.log(this.addWayBillForm);
    console.log(this.addWayBillForm.value);
    if (this.addWayBillForm.invalid) {
      this.toastr.info("Enter valid details");
    } else {

      if (!(this.addWayBillForm.value.hasOwnProperty('receiver_id'))) {
        this.show_receiver = false;
        this.toastr.info("Add or select receiver");
      } else {
        this.waybillService.addWayBill(this.addWayBillForm.value).subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.toastr.success(res.message, 'Success');
            this.addWayBillForm.reset();
            this.commoditiesArray.clear();
            // this.receiversCtrl.reset();
            // this.receiversFilterCtrl.reset();
            this.onAddNewRow();
            this.way_bill_id = res.id;
            this.way_bill = res.data.data;
            this.commodities = res.data.consignment;
            this.last_waybill = res.waybill_no;
            this.waybill_no = res.waybill_no + 1;
            console.log(this.way_bill);
            this.show_receiver = false;
            $('#showWayBill').modal('show');
            this.created.emit();

            this.addWayBillForm.patchValue({
              shipment_mode: '0'
            });
          } else {
            // this.submitted = false;
            this.toastr.warning(res.message, 'Warning');
          }
        });
      }
    }
    this.submitted = false;
  }

  onPrint() {
    console.log("Print Way Bill Button Pressed");
  }

  /**
   * Filter commodities
   * @param key 
   * @returns 
   */
  searchItem(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchItem(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.item_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.items);
      this.item_list = [];

      this.item_list = this.items.filter(item => item.item_name.toLowerCase().includes(svalue))
      console.log(this.item_list);
    }
  }

  getCommodityDetails(item, index) {
    this.commodity = item;
    this.selected_commodity = item;
    console.log(this.commodity);
  }

  /**
   * Filter commodities country
   * @param key 
   * @returns 
   */
  searchItemCountry(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchItem(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.item_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.countries);
      this.country_list = [];

      this.country_list = this.countries.filter(country => country.country_name.toLowerCase().includes(svalue))
      console.log(this.country_list);
    }
  }

  getCountryDetails(item) {
    this.country = item;
    this.selected_country = item;
    console.log(this.country);
  }

  /**
   * Filter Sender details based on user input
   * @param key 
   * @returns 
   */
  searchSenders(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchSenders(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.sender_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.senders);
      this.sender_list = [];

      this.sender_list = this.senders.filter(sender => sender.customer_name.toLowerCase().includes(svalue))
      console.log(this.sender_list);
    }
  }

  getSenderDetails(item) {
    this.sender = item;
    this.selected_sender = item;
    console.log(this.selected_sender);
  }

  /**
   * Filter Receivers based on user input
   * @param key 
   * @returns 
   */
  searchReceivers(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }

      // this.waybillService.searchReceivers(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.receiver_list = res.data;
      // });

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.receivers);
      this.receiver_list = [];

      this.receiver_list = this.receivers.filter(receiver => receiver.customer_name.toLowerCase().includes(svalue))
      console.log(this.receiver_list);
    }
  }

  // selectedReceiverDetails(details) {
  //   console.log(details);
  // }

  getReceiverDetails(item) {
    this.receiver = item;
    this.receiver_details = item;
    console.log(this.receiver_details);
    console.log(item);
    this.show_receiver = true;
    this.add_receiver = false;
    // var new_receiver = this.receivers.filter(x => x.customer_id === item.customer_id);
    // this.receiver_details = new_receiver[0];
    // console.log(this.receiver_details);
  }

  /**
   * Filter origin check points from check point list
   * @param key 
   * @returns 
   */
  searchOrigin(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchCheckPoints(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.origin_list = res.data;
      // });

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.origin_list = [];

      this.origin_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.origin_list);
    }
  }
  getOriginDetails(item) {
    this.origin = item;
    this.origin_details = item;
    console.log(this.origin_details);
    console.log(item);
  }

  /**
   * Filter destination list from check point list
   * @param key 
   * @returns 
   */
  searchDestination(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchCheckPoints(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.destination_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.destination_list = [];

      this.destination_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.destination_list);
    }
  }

  getDestinationDetails(item) {
    this.destination = item;
    this.destination_details = item;
    console.log(this.destination_details);
    console.log(item);
  }
  displayFn(sender?: Sender): string | undefined {
    return sender ? sender.customer_name : undefined;
  }
  displayFn1(receiver?: Receiver): string | undefined {
    return receiver ? receiver.customer_name : undefined;
  }
  displayFn2(item?: Commodity): string | undefined {
    return item ? item.item_name : undefined;
  }
  displayFn3(origin?: Checkpoints): string | undefined {
    return origin ? origin.check_point : undefined;
  }
  displayFn4(destination?: Checkpoints): string | undefined {
    return destination ? destination.check_point : undefined;
  }
  displayFn5(country?: Countries): string | undefined {
    return country ? country.country_name : undefined;
  }

}
