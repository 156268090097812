import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TripService } from 'app/services/trip.service';

@Component({
  selector: 'app-way-billmodal-component',
  templateUrl: './way-billmodal-component.component.html',
  styleUrls: ['./way-billmodal-component.component.scss']
})
export class WayBillmodalComponentComponent implements OnInit {

  waybill_trip_view: any;
  trip_exist: boolean;
  trip_movement: any;
  origin: any;
  destination: any;
  status: any;
  ind_trip_details: any;
  trip_id: any;
  waybill: any;

  constructor(private tripServices: TripService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public datas: any) {
    this.waybill = datas.waybill;
    console.log(datas);
  }

  ngOnInit(): void {
  }

  onSelectWayBill(waybill) {
    console.log(waybill);
    this.waybill_trip_view = waybill;
    if (waybill.trip_id != null) {
      this.trip_exist = true;
      this.getTripDetails(this.waybill_trip_view.trip_id);
    } else {
      this.trip_exist = false;
    }
  }
  getTripDetails(trip_id) {
    this.tripServices.getTripDetails(trip_id).subscribe((res: any) => {
      console.log(res);
      this.trip_movement = res.data;
      this.origin = res.origin;
      this.destination = res.destination;
      this.status = res.status;
      this.ind_trip_details = this.trip_movement[this.trip_movement.length - 1];
      console.log(this.ind_trip_details);
    });
  }

}
