<!--  -->
<!-- <div class="logo {{isConnected?'bg-online':'bg-offline'}}">
    <a href="" class="simple-text">
        <div class="logo-img ">
            <img src="assets/img/sidebar-logo.png" />
        </div>
        <h4 *ngIf="!isConnected" class="card-category"><span class="text-warning"><i
                    class="material-icons">signal_cellular_connected_no_internet_4_bar</i>Offline</span></h4>
        <h4 *ngIf="isConnected" class="card-category"><span class="text-success"><i
                    class="material-icons">signal_cellular_alt</i>Online</span></h4>
    </a>
</div> -->
<div style="background-color: #15506a !important;" class="logo {{isConnected?'bg-online':'bg-offline'}}">
    <a href="" class="simple-text">
        <!-- <img style="width: 50px; margin-left: 21px; border-radius: 50%;" src="../../../assets/img/invoice-1.jpg"> -->
        <h4> <img  style="width: 43px; margin-left: -64px; border-radius: 50%;" src="../../../assets/img/invoice-1.jpg"> <b style="margin-left: 10px;">GEL Cargo</b></h4>
    </a>
</div>
<!--  -->
<div  style="background-color: #15506a !important;" class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">

        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" (click)="logout()">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Log Out</span>
                    </p>
                </a>
            </li>

            <li routerLinkActive="active" class="nav-item">
                <a class="nav-link" routerLink="/my-account">
                    <i class="material-icons">person</i>
                    <p>MY ACCOUNT</p>
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav">

        <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/home">
                <i class="material-icons">home</i>
                <p>HOME</p>
            </a>
        </li>

        <!-- <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/my-account">
                <i class="material-icons">person</i>
                <p>MY ACCOUNT</p>
            </a>
        </li> -->

        <li class="nav-item" routerLinkActive="active" *ngFor="let menuItem of menuItems">

            <a class="nav-link" href="#{{menuItem?.menuname}}" data-toggle="collapse" aria-expanded="false"
                aria-controls="collapseExample">
                <i class="material-icons">{{menuItem?.icon_class}}</i>
                <p>{{menuItem?.menu_name | uppercase}}</p>
            </a>
            <div class="collapse" id="{{menuItem?.menuname}}">
                <ul class="nav">
                    <li routerLinkActive="active" class="nav-item" *ngFor="let menu of menuItem?.childs">
                        <a class="nav-link" routerLink="/{{menu.sub_menu_url}}">
                            <!-- <i class="material-icons">{{menu?.sub_menu_icon_class}}</i> -->
                            <i class="material-icons">{{menu?.sub_menu_icon_class}}</i><span
                                class="inline">{{menu?.sub_menu_name | uppercase}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>

        <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/waybill/waybill-rate-calculation">
                <i class="material-icons">calculate</i>
                <p>Calculation</p>
            </a>
        </li>

    </ul>
</div>