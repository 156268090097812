import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { UserService } from 'app/services/user.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';


declare var $: any;

export interface Sender {
  customer_id: number,
  customer_name: string
}
export interface Receiver {
  customer_id: number,
  customer_name: string,
  customer_code: string,
  customer_code_id: number,
  customer_mobile: string,
  country_name: string
}
export interface Items {
  id: number;
  item_name: string;
}

export interface Countries {
  id: number;
  country_name: string;
}
export interface Checkpoints {
  id: number,
  check_point: string
}
interface Commodity {
  id: number,
  item_group_id: number,
  item_country: number,
  item_name: string,
  item_unit: string
}


@Component({
  selector: 'app-waybill-create',
  templateUrl: './waybill-create.component.html',
  styleUrls: ['./waybill-create.component.scss']
})
export class WaybillCreateComponent implements OnInit {
  @Output() created = new EventEmitter();

  // Short key start
  mac = 'command+s';
  win = 'ctrl+s';
  isMac = navigator.platform.includes('Mac');
  saveCommand = this.isMac ? this.mac : this.win;
  saveCommandTitle = this.isMac ? '⌘+s' : this.win;

  // cancel key
  mac1 = 'command+c';
  win1 = 'ctrl+c';
  isMac1 = navigator.platform.includes('Mac');
  cancelCommand = this.isMac1 ? this.mac1 : this.win1;
  cancelCommandTitle = this.isMac1 ? '⌘+c' : this.win1;

  // Add Reciever Button short Key
  macR = 'command+r';
  winR = 'alt+r';
  isMacR = navigator.platform.includes('Mac');
  saveCommandR = this.isMacR ? this.macR : this.winR;
  saveCommandTitleR = this.isMacR ? '⌘+r' : this.winR;

  // Add Reciever Submit short Key
  macS = 'command+s';
  winS = 'alt+s';
  isMacS = navigator.platform.includes('Mac');
  saveCommandS = this.isMacS ? this.macS : this.winS;
  saveCommandTitleS = this.isMacS ? '⌘+s' : this.winS;

  // Add Commodity short Key
  macC = 'command+a';
  winC = 'alt+a';
  isMacC = navigator.platform.includes('Mac');
  saveCommandC = this.isMacC ? this.macC : this.winC;
  saveCommandTitleC = this.isMacC ? '⌘+a' : this.winC;

  // delete Commodity short Key 
  macB = 'command+b';
  winB = 'alt+b';
  isMacB = navigator.platform.includes('Mac');
  saveCommandB = this.isMacB ? this.macB : this.winB;
  saveCommandTitleB = this.isMacB ? '⌘+b' : this.winB;

  // Short key end

  senders: Sender[] = [];
  items: Items[] = [];
  check_points: Checkpoints[] = [];
  countries: Countries[] = [];
  protected receivers: Receiver[] = [];
  receiver_details: Receiver;

  last: any;
  customerForm: FormGroup;
  selected: number;
  field = false;
  way_bill_id: any;
  way_bill: any;
  commodities: any;
  add_receiver = false;
  added_receiver_id: any;
  show_receiver = false;
  to_show_receiver: any;
  today: any;
  submitted: boolean;
  add_disable = false;
  item_list: any;
  commodity: any;
  selected_commodity: any;
  sender: any;
  selected_sender: any;
  sender_list: any;
  receiver: any;
  selected_receiver: any;
  receiver_list: any;
  origin: any;
  origin_details: any;
  origin_list: any;
  destination_list: any;
  destination: any;
  destination_details: any;
  code_id: number;
  waybill_date: any;
  last_waybill: any;
  addWayBillForm: FormGroup;
  country_list: any;
  country: any;
  selected_country: any;
  waybill_no: any;

  constructor(private formbuilder: FormBuilder, private waybillService: WaybillService, private userService: UserService, private toastr: ToastrService, private datePipe: DatePipe, private hotkeysService: HotkeysService) {
    this.hotkeysService.add([
      new Hotkey(
        this.saveCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.createWayBill();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );


    // Add Reciever Button short Key

    this.hotkeysService.add([
      new Hotkey(
        this.saveCommandR, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.addReceiver();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );

    // Add Reciever Submit short Key

    this.hotkeysService.add([
      new Hotkey(
        this.saveCommandS, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onAddCustomer();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );


    // Add Commodity short Key
    this.hotkeysService.add([
      new Hotkey(
        this.saveCommandC, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onAddNewRow();
          // this.onAddCustomer();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );

    // delete Commodity short Key
    this.hotkeysService.add([
      new Hotkey(
        this.saveCommandB, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onRemoveRow(i);
          // this.onAddCustomer();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );

  }

  ngOnInit(): void {

    this.code_id = 1001;
    var today1 = new Date();
    this.today = this.datePipe.transform(today1, 'yyyy-MM-dd');
    this.waybill_date = this.datePipe.transform(today1, 'yyyy-MM-dd');

    this.getSendersNReceivers();
    this.getCheckPoints();
    this.getLastGeneratedNumber();
    this.getItems();

    this.userService.getCountries().subscribe((res: any) => {
      console.log(res);
      this.countries = res.countries;
      this.country_list = res.countries;
    });

    this.addWayBillForm = this.formbuilder.group({
      waybill_no: ['',],
      waybill_date: this.waybill_date,
      sender_id: ['', Validators.required],
      receiver_id: ['', Validators.required],
      narration: ['',],
      commodities: this.formbuilder.array([this.createNewItem()]),
      origin_check_id: [,],
      destination_check_id: [,],
      shipment_mode: '1',
      weight: [,],
      measurement_l: [,],
      measurement_w: [,],
      measurement_h: [,],
    });

    this.customerForm = this.formbuilder.group({
      customer_id: ['',],
      customer_type: ['',],
      customer_name: ['', Validators.required],
      customer_code: ['', Validators.required],
      customer_code_id: [''],
      customer_address: ['',],
      customer_mobile: ['', Validators.required],
      customer_email: ['',],//Validators.required],
      country_id: ['',],
      status: ['',]
    });
  }

  /**
   * Commodities
   */
  getItems() {
    this.waybillService.getItems().subscribe((res: any) => {
      console.log(res);
      this.items = res.items;
      this.item_list = res.items;
    });
  }

  getLastGeneratedNumber() {
    this.waybillService.getLastGeneratedNo().subscribe((res: any) => {
      console.log(res);
      this.last_waybill = res.waybill_no;
      this.waybill_no = res.waybill_no + 1;
    })
  }

  getSendersNReceivers() {
    this.userService.getSendersNReceivers().subscribe((res: any) => {
      console.log(res);
      this.senders = res.senders;
      this.receivers = res.receivers;
      this.sender_list = res.senders;
      this.receiver_list = res.receivers;
    });
  }

  getCheckPoints() {
    this.waybillService.getCheckPoints().subscribe((res: any) => {
      console.log(res);
      this.check_points = res.check_points;
      this.origin_list = res.check_points;
      this.destination_list = res.check_points;
    })
  }

  addSender() {
    this.customerForm.patchValue({
      customer_type: 1,
      status: 1
    });
  }

  addReceiver() {
    this.show_receiver = false;

    this.add_receiver = !this.add_receiver

    if (this.add_receiver) {
      this.userService.getNextReceiverId().subscribe((res: any) => {
        this.code_id = res.customer_code_id;
      })
    }

    this.customerForm.patchValue({
      customer_name: this.addWayBillForm.get('receiver_id').value,
      customer_type: 2,
      status: 1
    });
  }

  customerCodeGeneration() {
    let str = this.customerForm.value.customer_name;
    str = str.toUpperCase();
    let acronym = '';
    let word_count = str.split(' ').length;
    if (word_count == 1)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 4), '')
    else if (word_count == 2)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
    else
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')

    if (acronym.length < 4)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
    else if (acronym.length < 4)
      acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 4), '')

    acronym = acronym.slice(0, 4)
    this.customerForm.patchValue({
      customer_code: acronym
    })
  }

  onAddCustomer() {

    this.add_disable = true;
    console.log(this.customerForm.value);

    if (this.customerForm.invalid) {
      this.toastr.info("Enter valid details");
      this.add_disable = false;
    } else {
      this.customerForm.patchValue({
        customer_code_id: this.code_id
      })

      this.userService.addCustomer(this.customerForm.value).subscribe((res: any) => {
        this.add_disable = false;
        console.log(res);

        if (res.success) {
          $('#addCustomerForm').modal('hide');
          this.toastr.success(res.message, 'Success');
          // this.getSenders();
          console.log(res.customer_id);
          this.added_receiver_id = res.customer_id;

          // Set receiver start
          this.receivers = res.receivers;
          this.receiver_list = res.receivers;

          this.customerForm.patchValue({
            customer_id: res.customer_id
          })

          this.add_receiver = false;
          this.receiver_details = this.customerForm.value;
          this.addWayBillForm.patchValue({
            receiver_id: this.receiver_details
          });

          this.customerForm.reset();
          console.log(this.addWayBillForm.value);
        } else {
          this.toastr.warning(res.message, 'Warning');
        }
      });
    }
  }

  onAddNewRow() {
    this.commoditiesArray.push(this.createNewItem());
  }

  onRemoveRow(index) {
    this.commoditiesArray.removeAt(index);
  }

  get commoditiesArray() {
    return <FormArray>this.addWayBillForm.get("commodities");
  }

  createNewItem(): FormGroup {
    return this.formbuilder.group({
      item: ['', Validators.required],
      item_nos: [, [Validators.required, Validators.min(1)]],
      item_country: ['',],
    });
  }

  addItem() {
    console.log("add item button pressed");
  }
  get w() {
    return this.addWayBillForm.controls;
  }
  createWayBill() {

    console.log(this.addWayBillForm.value);

    this.submitted = true;
    this.waybill_date = this.datePipe.transform(this.waybill_date, 'yyyy-MM-dd');

    this.addWayBillForm.patchValue({
      waybill_date: this.waybill_date,
      waybill_no: this.waybill_no,
    });

    console.log(this.addWayBillForm);
    console.log(this.addWayBillForm.value);
    if (this.addWayBillForm.invalid) {
      this.toastr.info("Enter valid details");
    } else {

      if (!(this.addWayBillForm.value.hasOwnProperty('receiver_id'))) {
        this.show_receiver = false;
        this.toastr.info("Add or select receiver");
      } else if (typeof this.addWayBillForm.value.receiver_id === 'string') {
        console.log("String");
        this.add_receiver = false;
        this.addReceiver()
        this.toastr.info("Enter receiver details");
      } else {
        this.waybillService.addWayBill(this.addWayBillForm.value).subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.toastr.success(res.message, 'Success');
            this.addWayBillForm.reset();
            this.commoditiesArray.clear();
            this.onAddNewRow();
            this.way_bill_id = res.id;
            this.way_bill = res.data.data;
            this.commodities = res.data.consignment;
            this.last_waybill = res.waybill_no;
            this.waybill_no = res.waybill_no + 1;
            console.log(this.way_bill);
            this.show_receiver = false;
            $('#showWayBill').modal('show');
            this.created.emit();
            this.addWayBillForm.patchValue({
              shipment_mode: '1'
            });
          } else {
            this.toastr.warning(res.message, 'Warning');
          }
        });
      }
    }
    this.submitted = false;
  }

  onPrint() {
    console.log("Print Way Bill Button Pressed");
  }

  /**
   * Filter commodities
   * @param key 
   * @returns 
   */
  searchItem(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.items);
      this.item_list = [];

      this.item_list = this.items.filter(item => item.item_name.toLowerCase().includes(svalue))
      console.log(this.item_list);
    }
  }

  getCommodityDetails(item, index) {
    this.commodity = item;
    this.selected_commodity = item;
    console.log(this.commodity);
  }

  /**
   * Filter commodities country
   * @param key 
   * @returns 
   */
  searchItemCountry(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.countries);
      this.country_list = [];

      this.country_list = this.countries.filter(country => country.country_name.toLowerCase().includes(svalue))
      console.log(this.country_list);
    }
  }

  getCountryDetails(item) {
    this.country = item;
    this.selected_country = item;
    console.log(this.country);
  }

  /**
   * Filter Sender details based on user input
   * @param key 
   * @returns 
   */
  searchSenders(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.senders);
      this.sender_list = [];

      this.sender_list = this.senders.filter(sender => sender.customer_name.toLowerCase().includes(svalue))
      console.log(this.sender_list);
    }
  }

  getSenderDetails(item) {
    this.sender = item;
    this.selected_sender = item;
    console.log(this.selected_sender);
  }

  /**
   * Filter Receivers based on user input
   * @param key 
   * @returns 
   */
  searchReceivers(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.receivers);
      this.receiver_list = [];

      this.receiver_list = this.receivers.filter(receiver => receiver.customer_name.toLowerCase().includes(svalue))
      console.log(this.receiver_list);
    }
  }

  getReceiverDetails(item) {
    this.receiver = item;
    this.receiver_details = item;
    console.log(this.receiver_details);
    console.log(item);
    this.show_receiver = true;
    this.add_receiver = false;

  }

  /**
   * Filter origin check points from check point list
   * @param key 
   * @returns 
   */
  searchOrigin(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.origin_list = [];

      this.origin_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.origin_list);
    }
  }
  getOriginDetails(item) {
    this.origin = item;
    this.origin_details = item;
    console.log(this.origin_details);
    console.log(item);
  }

  /**
   * Filter destination list from check point list
   * @param key 
   * @returns 
   */
  searchDestination(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.destination_list = [];

      this.destination_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.destination_list);
    }
  }

  getDestinationDetails(item) {
    this.destination = item;
    this.destination_details = item;
    console.log(this.destination_details);
    console.log(item);
  }
  displayFn(sender?: Sender): string | undefined {
    return sender ? sender.customer_name : undefined;
  }
  displayFn1(receiver?: Receiver): string | undefined {
    return receiver ? receiver.customer_name : undefined;
  }
  displayFn2(item?: Commodity): string | undefined {
    return item ? item.item_name : undefined;
  }
  displayFn3(origin?: Checkpoints): string | undefined {
    return origin ? origin.check_point : undefined;
  }
  displayFn4(destination?: Checkpoints): string | undefined {
    return destination ? destination.check_point : undefined;
  }
  displayFn5(country?: Countries): string | undefined {
    return country ? country.country_name : undefined;
  }

}
function i(i: any) {
  throw new Error('Function not implemented.');
}

