import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PrintServiceService } from 'app/services/print-service.service';
import { TripService } from 'app/services/trip.service';
import { UserService } from 'app/services/user.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-waybill-veiw-component',
  templateUrl: './waybill-veiw-component.component.html',
  styleUrls: ['./waybill-veiw-component.component.scss']
})
export class WaybillVeiwComponentComponent implements OnInit {

  way_bills: any;
  ind_way_bill: any;
  search = false;
  way_bills_search: any;
  way_bill_customer: any;
  way_bill_number: any;
  show_way_bill = false;
  print_or_export = false;
  way_bill: any;
  commodities: any[];
  displayedWayBillsDaily = ['index', 'waybill_no', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'loaded_pkg', 'narration'];
  displayedWayBills = ['index', 'waybill_date', 'waybill_no', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'loaded_pkg', 'check_point', 'tracking_position_status', 'narration', 'action'];
  datasource = new MatTableDataSource<any>();
  datasourceDaily = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table1', { read: MatSort, static: true }) sort1: MatSort;
  editWayBillForm: FormGroup;
  countries: any;
  items: any;
  senders: any;
  receivers: any;
  check_points: any;
  edit_ind_way_bill: any;
  edit_way_bill: any;
  commodities1: any;
  ind_way_bill1: any;
  way_bill_id: any;
  selectedTab: number;
  searchValue: any;
  wayBillNo: any;
  ind_trip: any;
  trip_movement: any;
  origin: any;
  destination: any;
  status: any;
  ind_trip_details: any;
  waybill_trip_view: any;
  trip_exist: boolean;
  additional_data: any;
  from: Date;
  to: Date;
  way_bill_statistics: any;
  searchNameValue: any;
  selected: number;
  year_count: any;
  day_count: any;
  month_count: any;
  average: any;
  month: any;
  today: string;
  agreed: any;
  disagreed: any;
  today_date: string;
  year: any;

  constructor(private waybillService: WaybillService, private tripService: TripService, private userService: UserService, private pipe: DatePipe, private printService: PrintServiceService, private formbuilder: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.selected = 0;
    this.year = this.pipe.transform(this.from, 'yyyy');

    this.dateCallOnInit();
    this.getWayBillStatistics();
  }

  getDaily() {
    const from = this.pipe.transform(this.from, 'yyyy-MM-dd');
    const to = this.pipe.transform(this.to, 'yyyy-MM-dd');
    this.waybillService.getWayBillsBetween(from, to).subscribe((res: any) => {
      console.log(res);
      this.datasourceDaily = new MatTableDataSource(res.data);
      this.datasourceDaily.sort = this.sort;
    });
  }
  reloadPage() {
    this.dateCallOnInit();
    this.getWayBillStatistics();
    this.getReceivers();
    this.getSenders();
    this.getItems();
    this.getDaily();
  }
  dateCallOnInit() {
    this.from = new Date();
    this.to = new Date();
  }
  getItems() {
    this.waybillService.getItems().subscribe((res: any) => {
      console.log(res);
      this.items = res.items;
    });
  }

  getWayBillStatistics() {
    this.from = new Date();
    this.today = this.pipe.transform(this.from, 'yyyy-MM-dd');
    this.today_date = this.pipe.transform(this.from, 'dd-MM-yyyy');
    this.month = this.pipe.transform(this.from, 'MMMM');
    this.waybillService.getWayBillStatistics(this.today).subscribe((res: any) => {
      console.log(res);
      this.year = res.year;
      this.year_count = res.year_count.count;
      this.month_count = res.month_count.count;
      this.day_count = res.day_count.count;
      this.average = res.average;
    });
  }
  getWaybills() {
    this.waybillService.getWayBills().subscribe((res: any) => {
      console.log(res);
      this.way_bills = res.data;
      this.datasource = new MatTableDataSource(this.way_bills);
      this.datasource.sort = this.sort;
    });
  }
  searchByCustomer(key) {
    this.way_bill_number = [];
    this.waybillService.searchWayBillCustomer(key).subscribe((res: any) => {
      console.log(res);
      this.way_bills = res.data;
      this.datasource = new MatTableDataSource(this.way_bills);
      this.datasource.sort = this.sort;
    });
  }
  export() {
    var file_name = 'Way bill';
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('wayBillTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    ws['!rows'][this.datasource.data.length + 1] = { hidden: false };
    ws['!cols'] = [];
    ws['!cols'][11] = { hidden: true };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, filename);
  }
  searchIndWayBIll(id) {
    this.waybillService.getIndWayBill(id).subscribe((res: any) => {
      console.log(res);
      this.show_way_bill = true;
      this.way_bill = res.data;
      this.commodities = res.data1;
      this.additional_data = res.additional_data;
      if (this.commoditiesArray.length != 0) {
        this.commoditiesArray.clear();
      }
      this.commodities.forEach(x => {
        this.commoditiesArray.push(this.formbuilder.group(x));
      });
      this.edit_way_bill = this.way_bill;
      this.editWayBillForm.patchValue({
        waybill_no: this.way_bill.waybill_no,
        sender_id: this.way_bill.sender_id,
        receiver_id: this.way_bill.receiver_id,
        origin_check_id: this.way_bill.origin_check_id,
        destination_check_id: this.way_bill.destination_check_id,
        shipment_mode: this.way_bill.shipment_mode,
        weight: this.way_bill.weight,
        measurement_l: this.way_bill.measurement_l,
        measurement_w: this.way_bill.measurement_w,
        measurement_h: this.way_bill.measurement_h,
      });
    }, _error => {
      this.show_way_bill = false;
    });
  }
  onPrintWaybill(waybill_no) {
    console.log(waybill_no);
    this.printService.printSingleDocument('waybill-print', waybill_no);
  }
  getSenders() {
    this.userService.getSenders().subscribe((res: any) => {
      console.log(res);
      this.senders = res.senders;
    });
  }
  getReceivers() {
    this.userService.getReceivers().subscribe((res: any) => {
      console.log(res);
      this.receivers = res.receivers;
    });
  }
  getCheckPoints() {
    this.waybillService.getCheckPoints().subscribe((res: any) => {
      console.log(res);
      this.check_points = res.check_points;
    })
  }
  onAddNewRow() {
    this.commoditiesArray.push(this.createNewItem());
  }
  onRemoveRow(index) {
    if (this.removedItemsArray.length == 0) {
      this.removedItemsArray.clear();
    }
    if (this.commoditiesArray.value[index].consignment_id == null) {
      this.commoditiesArray.removeAt(index);
    }
    else if (this.commoditiesArray.value[index].loaded == 0) {
      this.commoditiesArray.value[index].quantity = 0;
      this.removedItemsArray.push(this.formbuilder.group(this.commoditiesArray.value[index]));
      console.log(this.removedItemsArray);
      this.commoditiesArray.removeAt(index);
    } else {
      this.toastr.info("Commodity already added to trip", 'Info')
    }
  }
  get commoditiesArray() {
    return <FormArray>this.editWayBillForm.get("commodities");
  }
  get removedItemsArray() {
    return <FormArray>this.editWayBillForm.get("removed_items");
  }
  createNewItem(): FormGroup {
    return this.formbuilder.group({
      consignment_id: [],
      item_id: [],
      quantity: []
    });
  }
  oneditWayBill() {
    console.log(this.editWayBillForm.value);
    this.waybillService.editWayBill(this.editWayBillForm.value).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.toastr.success(res.message, 'Success');
        this.editWayBillForm.reset();
        this.way_bill_id = res.id;
        this.way_bill = res.data.data;
        this.commodities = res.data.data1;
        console.log(this.way_bill);
        $('#WayBillModal').modal('hide');
        this.getWaybills();
        this.searchByNumber(this.way_bill_id);
        this.searchIndWayBIll(this.way_bill_id);
        this.searchByCustomer(this.way_bill_id);
      } else {
        this.toastr.warning(res.message, 'Warning');
      }
    });

  }
  onChangeTab($event) {
    console.log($event);
    this.selectedTab = $event;
  }
  onSelectWayBill(waybill) {
    console.log(waybill);
    this.waybill_trip_view = waybill;
    if (waybill.trip_id != null) {
      this.trip_exist = true;
      this.getTripDetails(this.waybill_trip_view.trip_id);
    } else {
      this.trip_exist = false;
    }
  }
  getTripDetails(trip_id) {
    this.tripService.getTripDetails(trip_id).subscribe((res: any) => {
      console.log(res);
      this.trip_movement = res.data;
      this.origin = res.origin;
      this.destination = res.destination;
      this.status = res.status;
      this.ind_trip_details = this.trip_movement[this.trip_movement.length - 1];
      console.log(this.ind_trip_details);
    });
  }
  onCancelEditWayBill() {
    this.getWaybills();
    this.searchByNumber(this.way_bill_id);
    this.searchIndWayBIll(this.way_bill_id);
    this.searchByCustomer(this.way_bill_id);
    this.removedItemsArray.clear();
  }
  submit_date() {
    this.searchValue = [];
    this.searchNameValue = [];
    if (this.from == null || this.to == null) {
      this.toastr.info('Select valid date');
      return;
    }
    const from = this.pipe.transform(this.from, 'yyyy-MM-dd');
    const to = this.pipe.transform(this.to, 'yyyy-MM-dd');
    console.log(from);
    console.log(to);
    if (new Date(from) > new Date(to)) {
      this.toastr.info('Select valid date');
      return;
    }
    this.waybillService.getWayBillsBetween(from, to).subscribe((res: any) => {
      console.log(res);
      this.way_bills = res.data;
      this.datasource = new MatTableDataSource(res.data);
      this.datasource.sort = this.sort;
    });
  }
  searchByNumber(id) {
    console.log(id);
    this.waybillService.searchWayBillNumber(id).subscribe((res: any) => {
      console.log(res);
      this.way_bills = res.data;
      this.datasource = new MatTableDataSource(res.data);
      this.datasource.sort = this.sort;
    });
  }
  onChange($event) {
    console.log($event);
    this.selected = $event;
  }
  onVoted(agreed: boolean) {
    agreed ? this.agreed++ : this.disagreed++;
  }
  /**
  * to get daily waybill Export
  */
  exportDaily() {
    var file_name = 'Waybill ' + this.today_date;
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('dailyTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    ws['!rows'][this.datasourceDaily.data.length + 1] = { hidden: false };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, filename);
  }
  getQuantityTotal() {
    return this.datasourceDaily.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
  }
  getLoadedTotal() {
    return this.datasourceDaily.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
  }
  getQuantityTotal1() {
    return this.datasource.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
  }
  getLoadedTotal1() {
    return this.datasource.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
  }

}