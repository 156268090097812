import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ExportService } from 'app/services/export.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-consignment',
  templateUrl: './consignment.component.html',
  styleUrls: ['./consignment.component.scss']
})
export class ConsignmentComponent implements OnInit {

  // short key
  mac = 'command+s';
  win = 'ctrl+s';
  isMac = navigator.platform.includes('Mac');
  saveCommand = this.isMac ? this.mac : this.win;
  saveCommandTitle = this.isMac ? '⌘+s' : this.win;

  mac1 = 'command+c';
  win1 = 'ctrl+c';
  isMac1 = navigator.platform.includes('Mac');
  cancelCommand = this.isMac1 ? this.mac1 : this.win1;
  cancelCommandTitle = this.isMac1 ? '⌘+c' : this.win1;

  consignment: any;
  report: FormGroup;
  consignment_report: any;
  datasource = new MatTableDataSource<any>();
  datasource1 = new MatTableDataSource<any>();
  dataSourceEmpty = [];
  displayedConsignmentOnBoard = ['index', 'waybill_date', 'id', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'tracking_position_status'];
  displayedConsignmentHistory = ['index', 'waybill_date', 'id', 'sender_name', 'receiver_name', 'item_name', 'quantity', 'loaded_pkg', 'trip_name', 'check_point', 'tracking_position_status'];


  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sort1', { static: true }) sort1: MatSort;
  print_or_export = false;
  from: any;
  to: any;

  constructor(private waybillservice: WaybillService, private _formbuilder: FormBuilder, private pipe: DatePipe, private toastr: ToastrService, private hotkeysService: HotkeysService) {

    this.hotkeysService.add([
      new Hotkey(
        this.saveCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.submit_date();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );
  }

  ngOnInit(): void {
    this.getFullConsignmentOnBoard();
    this.report = this._formbuilder.group({
      from: [new Date(), Validators.required],
      to: [new Date(), Validators.required]
    });
  }
  getFullConsignmentOnBoard() {
    this.waybillservice.getFullConsignmentOnBoard().subscribe(res => {
      this.consignment_report = res.report;
      this.datasource = new MatTableDataSource(res.report);
      // this.datasource.paginator = this.paginator
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort;
      console.log(res);
    });
  }
  submit_date() {
    this.datasource = new MatTableDataSource();
    this.datasource1 = new MatTableDataSource();
    console.log(this.report.value);
    // const from = this.pipe.transform(this.from.value, 'yyyy-MM-dd');
    // const to = this.pipe.transform(this.to.value, 'yyyy-MM-dd');
    const from = this.pipe.transform(this.from, 'yyyy-MM-dd');
    const to = this.pipe.transform(this.to, 'yyyy-MM-dd');
    if (from == null || to == null) {
      this.toastr.info('Select valid date');
      return;
    }
    if (new Date(from) > new Date(to)) {
      this.toastr.info('Select valid date');
      return;
    }
    this.waybillservice.getConsignmentOnBoard(from, to).subscribe(res => {
      this.consignment_report = res.report;
      this.datasource = new MatTableDataSource(res.report);
      this.datasource.sort = this.sort;
      console.log(res);
    });
    this.waybillservice.getConsignmentHistory(from, to).subscribe((res: any) => {
      console.log(res);
      this.consignment = res.data;
      this.datasource1 = new MatTableDataSource(res.data);
      this.datasource1.paginator = this.paginator.toArray()[1];

      this.datasource1.sort = this.sort1;
    });
  }
  get r() {
    return this.report.controls;
  }
  export() {
    var file_name = 'Consignment On Board';
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('consignmentOnBoard');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    ws['!rows'][this.datasource.data.length + 1] = { hidden: false };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, filename);
  }
  export1() {
    var file_name = 'Consignment History';
    const filename = file_name + '.' + 'xlsx';
    let element = document.getElementById('consignmentHistory');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!rows'] = [];
    ws['!rows'][this.datasource1.data.length + 1] = { hidden: false };
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, filename);
  }
  getBalanceTotal() {
    return this.datasource.data.map(t => Number(t.quantity - t.packed)).reduce((acc, value) => acc + value, 0);
  }
  getPackedTotal1() {
    return this.datasource1.data.map(t => Number(t.loaded_pkg)).reduce((acc, value) => acc + value, 0);
  }
  getQuantityTotal1() {
    return this.datasource1.data.map(t => Number(t.quantity)).reduce((acc, value) => acc + value, 0);
  }

}
