import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthserviceService } from 'app/auth/authservice.service';
import { GlobalConstants } from '../common/global-constants';
@Injectable({
  providedIn: 'root'
})
export class RandomGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthserviceService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    var resp = this.canLoad();
    if (resp) {
      var path = routerState.url;
      var path_list = GlobalConstants.path_list;
      var is_valid = path_list.indexOf(path);
      if (is_valid > -1) {
        window.localStorage.setItem('currentUrl', path);
        window.localStorage.setItem('previousUrl', this.router.url);
        return true
      } else {
        console.log(false);
        var lms_path = path.split("/");
        var new_path = "/" + lms_path[1] + "/" + lms_path[2]
        var is_valid = path_list.indexOf(new_path);
        if (is_valid > -1) {
          console.log("true");
          return true
        } else {
          console.log(false);

          var previousUrl = window.localStorage.getItem('previousUrl');
          var currentUrl = window.localStorage.getItem('currentUrl');
          var prev_list = [];
          prev_list.push(previousUrl);
          prev_list.push(currentUrl);

          var is_valid = prev_list.indexOf(path);
          if (is_valid > -1) {
            return true
          } else {
            this.router.navigate(['/home']);
          }
        }
      }
    } else {
      return resp;
    }
  }

  canLoad() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/signin']);
    }
    return this.authService.isLoggedIn();
  }
}
