import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-voucher-print',
  templateUrl: './voucher-print.component.html',
  styleUrls: ['./voucher-print.component.scss']
})
export class VoucherPrintComponent implements OnInit {

  voucher_bd: any;
  voucher_fils: any;
  today: any;
  voucher_date: any;
  addVoucherForm: FormGroup;
  date_voucher: any;

  constructor(private formbuilder: FormBuilder, private toastr: ToastrService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.addVoucherForm = this.formbuilder.group({

      paid_to: ['',],
      sum_bd: ['',],
      cash: ['',],
      date: ['',],
      bank: ['',],
      towards: ['',],
      manager: ['',],
      accountant: ['',],
      received_by: ['',],
    });
  }


  createVoucher() {
    console.log(this.addVoucherForm.value)

  }

}
