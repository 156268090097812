import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { PrintServiceService } from 'app/services/print-service.service';
import { UserService } from 'app/services/user.service';
import { WaybillService } from 'app/services/waybill.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

export interface Sender {
  customer_id: number,
  customer_name: string
}
export interface Receiver {
  customer_id: number,
  customer_name: string,
  customer_code: string,
  customer_code_id: number,
  customer_mobile: string,
  country_name: string
}
export interface Items {
  id: number;
  item_name: string;
}

export interface Countries {
  id: number;
  country_name: string;
}
export interface Checkpoints {
  id: number,
  check_point: string
}
interface Commodity {
  id: number,
  item_group_id: number,
  item_country: number,
  item_id: number,
  item_name: string,
  item_unit: string
}
@Component({
  selector: 'app-waybill-edit-component',
  templateUrl: './waybill-edit-component.component.html',
  styleUrls: ['./waybill-edit-component.component.scss']
})
export class WaybillEditComponentComponent implements OnInit {

  // short key
  mac = 'command+s';
  win = 'ctrl+s';
  isMac = navigator.platform.includes('Mac');
  saveCommand = this.isMac ? this.mac : this.win;
  saveCommandTitle = this.isMac ? '⌘+s' : this.win;

  mac1 = 'command+c';
  win1 = 'ctrl+c';
  isMac1 = navigator.platform.includes('Mac');
  cancelCommand = this.isMac1 ? this.mac1 : this.win1;
  cancelCommandTitle = this.isMac1 ? '⌘+c' : this.win1;

  // Add New Row short key

  macA = 'command+d';
  winA = 'shift+d';
  isMacA = navigator.platform.includes('Mac');
  saveCommandA = this.isMacA ? this.macA : this.winA;
  saveCommandTitleA = this.isMacA ? '⌘+d' : this.winA;

  // remove short key
  macB = 'command+s';
  winB = 'shift+b';
  isMacB = navigator.platform.includes('Mac');
  saveCommandB = this.isMacB ? this.mac : this.winB;
  saveCommandTitleB = this.isMacB ? '⌘+b' : this.winB;

  senders: Sender[] = [];
  items: Items[] = [];
  check_points: Checkpoints[] = [];
  countries: Countries[] = [];
  protected receivers: Receiver[] = [];
  receiver_details: Receiver;

  editWayBillForm: FormGroup;
  way_bills: any;
  datasource: MatTableDataSource<unknown>;
  sort: any;
  show_way_bill: boolean;
  commodities: any[];
  way_bill: any;
  wayBillNo: any;
  edit_way_bill: any;
  way_bill_id: any;
  selectedTab: any;
  sender_list: any;
  receiver_list: any;
  sender: any;
  selected_sender: any;
  receiver: any;
  show_receiver: boolean;
  add_receiver: boolean;
  origin_list: any[];
  origin: any;
  origin_details: any;
  destination_list: any[];
  destination: any;
  destination_details: any;
  sender_id: any;
  receiver_id: any;
  show_commodities: any;
  country_list: any[];
  country: any;
  selected_country: any;
  item_list: any[];

  constructor(private wayBillService: WaybillService, private printService: PrintServiceService, private userService: UserService, private formbuilder: FormBuilder, private toastr: ToastrService, private datePipe: DatePipe, private hotkeysService: HotkeysService) {

    this.hotkeysService.add([
      new Hotkey(
        this.saveCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.updateWaybillDetails();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      ),
      new Hotkey(
        this.cancelCommand, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          console.log("Cancel shortcut key");

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )]

    );

    // Add New Row short key

    this.hotkeysService.add([
      new Hotkey(
        this.saveCommandTitleA, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onAddNewRow();

          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )],

    );

    // delete Row short key

    this.hotkeysService.add(
      new Hotkey(
        this.saveCommandTitleB, //  key combination
        (): boolean => {
          // callback function to execute after key combination
          this.onRemoveRow(i);
          return false; // prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'save' // shortcut name
      )
    );
  }

  ngOnInit(): void {
    // console.log("WaybillEditComponentComponent");
    this.way_bill = false;

    // this.getWaybills();
    // this.getSenders();
    // this.getReceivers();
    this.getSendersNReceivers();
    this.getCheckPoints();

    this.wayBillService.getItems().subscribe((res: any) => {
      console.log(res);
      this.items = res.items;
      this.item_list = res.items;
    });

    this.userService.getCountries().subscribe((res: any) => {
      console.log(res);
      this.countries = res.countries;
    });

    this.editWayBillForm = this.formbuilder.group({
      id: [,],
      waybill_no: [,],
      waybill_date: ['', Validators.required],
      sender_id: ['', Validators.required],
      receiver_id: ['', Validators.required],
      commodities: this.formbuilder.array([]),
      origin_check_id: [],
      destination_check_id: [],
      shipment_mode: [,],
      weight: [,],
      narration: [,],
      measurement_l: [,],
      measurement_w: [,],
      measurement_h: [,],
      removed_items: this.formbuilder.array([]),
    });
  }

  get commoditiesArray() {
    return <FormArray>this.editWayBillForm.get("commodities");
  }

  get removedItemsArray() {
    return <FormArray>this.editWayBillForm.get("removed_items");
  }

  getSendersNReceivers() {
    this.userService.getSendersNReceivers().subscribe((res: any) => {
      console.log(res);
      this.senders = res.senders;
      this.receivers = res.receivers;

      this.sender_list = res.senders;
      this.receiver_list = res.receivers;
    });
  }

  getCheckPoints() {
    this.wayBillService.getCheckPoints().subscribe((res: any) => {
      console.log(res);
      this.check_points = res.check_points;
    })
  }

  /**
   * Find way bill by number
   * @param waybill_no 
   */
  searchIndWayBIll(waybill_no) {
    this.show_way_bill = false;
    this.way_bill = false;
    this.commoditiesArray.clear();
    this.commodities = [];

    this.wayBillService.getIndWayBill(waybill_no).subscribe((res: any) => {
      console.log(res);
      if (res.data) {
        this.show_way_bill = true;
        this.way_bill = res.data;
        this.commodities = res.consignment;
        let commo = []
        this.commodities.forEach(element => {
          if (element.quantity)
            commo.push(element)
        })
        this.show_commodities = commo;

        this.setEditWaybillCommodities();

        if (!this.commodities) {
          this.show_way_bill = false;
        }
      }
    });
  }

  /**
   * Filter commodities
   * @param key 
   * @returns 
   */
  searchItem(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.items);
      this.item_list = [];

      this.item_list = this.items.filter(item => item.item_name.toLowerCase().includes(svalue))
      console.log(this.item_list);
    }
  }

  getCommodityDetails(item, index) {
    console.log(item);

    // this.commodity = item;
    // this.selected_commodity = item;
    // console.log(this.commodity);
  }

  /**
   * Filter commodities country
   * @param key 
   * @returns 
   */
  searchItemCountry(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchItem(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.item_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.countries);
      this.country_list = [];

      this.country_list = this.countries.filter(country => country.country_name.toLowerCase().includes(svalue))
      console.log(this.country_list);
    }
  }

  getCountryDetails(item) {
    this.country = item;
    this.selected_country = item;
    console.log(this.country);
  }

  /**
   * Re print waybill
   */
  rePrintWayBill() {
    console.log(this.way_bill.waybill_no);
    this.printService.printDocument('waybill-print', this.way_bill.waybill_no); //waybill-print
  }

  /**
   * 
   */
  setEditWaybillCommodities() {

    this.sender_list = this.senders.filter(sender => sender.customer_id == this.way_bill.sender_id)
    console.log(this.sender_list);
    this.getSenderDetails(this.sender_list[0]);

    this.receiver_list = this.receivers.filter(receiver => receiver.customer_id == this.way_bill.receiver_id)
    this.getReceiverDetails(this.receiver_list[0]);

    // this.sender_id = this.sender_list;
    // this.receiver_id = this.receiver_list;

    this.commoditiesArray.clear();

    this.commodities.forEach(x => {

      x.item_country = this.countries.filter(country => country.id == x.item_country_id)
      x.item_id = this.items.filter(item => item.id == x.item_id)

      this.commoditiesArray.push(this.formbuilder.group(x));
      console.log(x);
    });

    this.edit_way_bill = this.way_bill;

    this.editWayBillForm.patchValue({
      id: this.way_bill.id,
      waybill_no: this.way_bill.waybill_no,
      waybill_date: this.way_bill.waybill_date,
      // sender_id: this.way_bill.sender_id,
      sender_id: this.sender_list[0],
      // receiver_id: this.way_bill.receiver_id,
      receiver_id: this.receiver_list[0],
      origin_check_id: this.way_bill.origin_check_id,
      destination_check_id: this.way_bill.destination_check_id,
      shipment_mode: this.way_bill.shipment_mode,
      weight: this.way_bill.weight,
      narration: this.way_bill.narration,
      measurement_l: this.way_bill.measurement_l,
      measurement_w: this.way_bill.measurement_w,
      measurement_h: this.way_bill.measurement_h,
    });
  }

  // getSenders() {
  //   this.userService.getSenders().subscribe((res: any) => {
  //     console.log(res);
  //     this.senders = res.senders;
  //   });
  // }
  // getReceivers() {
  //   this.userService.getReceivers().subscribe((res: any) => {
  //     console.log(res);
  //     this.receivers = res.receivers;
  //   });
  // }

  onAddNewRow() {
    this.commoditiesArray.push(this.createNewItem());
  }

  onRemoveRow(index) {
    if (this.removedItemsArray.length == 0) {
      this.removedItemsArray.clear();
    }
    if (this.commoditiesArray.value[index].consignment_id == null) {
      this.commoditiesArray.removeAt(index);
    }
    else if (this.commoditiesArray.value[index].loaded == 0) {
      this.commoditiesArray.value[index].quantity = 0;
      this.removedItemsArray.push(this.formbuilder.group(this.commoditiesArray.value[index]));
      console.log(this.removedItemsArray);
      this.commoditiesArray.removeAt(index);
    } else {
      this.toastr.info("Commodity already added to trip", 'Info');
    }
  }

  createNewItem(): FormGroup {
    return this.formbuilder.group({
      consignment_id: [],
      item_id: [],
      quantity: [],
      item_country: [],
      item_country_id: []
    });
  }

  get w() {
    return this.editWayBillForm.controls;
  }

  /**
   * Update waybill details
   */
  updateWaybillDetails() {
    console.log(this.editWayBillForm.value);
    console.log(this.selected_sender);

    if (this.selected_sender != this.editWayBillForm.value.sender_id) {
      console.log("Not Equal");
    }

    if (this.editWayBillForm.invalid) {
      this.toastr.warning("Enter valid details", 'Warning');
    } else {
      var flag = false;
      var minimum = false;
      // var new_commodities = this.w.commodities.value;

      for (var dat of this.w.commodities.value) {
        console.log(dat);
        if (dat.item_country)
          dat.item_country_id = dat.item_country.id;

        if (dat.item_id)
          dat.item_id = dat.item_id.id;

        if (dat.consignment_id == null) {
          if (dat.quantity <= 0) {
            minimum = true;
          }
        } else {
          if (dat.quantity < 0) {
            minimum = true;
          } else if (dat.quantity < dat.loaded) {
            flag = true;
          }
        }
      }
      console.log(this.w.commodities.value);

      if (minimum == true) {
        this.toastr.info("Commodity cannot be 0 or lesser", 'Info')
      } else if (flag == true) {
        this.toastr.info("Commodity already added to trip cannot be changed", 'Info')
      } else {

        this.editWayBillForm.patchValue({
          waybill_date: this.datePipe.transform(this.editWayBillForm.value.waybill_date, 'yyyy-MM-dd'),
        });

        this.wayBillService.editWayBill(this.editWayBillForm.value).subscribe((res: any) => {
          console.log(res);

          if (res.success) {
            this.toastr.success(res.message, 'Success');

            console.log(this.editWayBillForm.value.sender_id);
            console.log(this.selected_sender);

            if (this.selected_sender != this.editWayBillForm.value.sender_id) {
              console.log("Not Equal");
              // this.getSendersNReceivers();
              this.senders.push({
                customer_id: res.sender_id,
                customer_name: this.editWayBillForm.value.sender_id
              })
            }

            this.editWayBillForm.reset();
            this.way_bill_id = res.id;
            this.way_bill = res.data.data;
            this.commodities = res.data.consignment;
            let commo = []
            this.commodities.forEach(element => {
              if (element.quantity)
                commo.push(element)
            })
            this.show_commodities = commo;


            // console.log(this.way_bill);
            // $('#WayBillModal').modal('hide');
            this.setEditWaybillCommodities();
            // this.getWaybills();
            // this.searchIndWayBIll(this.way_bill_id);
          } else {
            this.toastr.warning(res.message, 'Warning');
          }
        });
      }
    }
  }

  // Search section starts

  /**
   * Filter Sender details based on user input
   * @param key 
   * @returns 
   */
  searchSenders(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.senders);
      this.sender_list = [];

      this.sender_list = this.senders.filter(sender => sender.customer_name.toLowerCase().includes(svalue))
      console.log(this.sender_list);
    }
  }

  getSenderDetails(item) {
    this.sender = item;
    this.selected_sender = item;
    console.log(this.selected_sender);
  }

  /**
   * Filter Receivers based on user input
   * @param key 
   * @returns 
   */
  searchReceivers(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.receivers);
      this.receiver_list = [];

      this.receiver_list = this.receivers.filter(receiver => receiver.customer_name.toLowerCase().includes(svalue))
      console.log(this.receiver_list);
    }
  }

  // selectedReceiverDetails(details) {
  //   console.log(details);
  // }

  getReceiverDetails(item) {
    this.receiver = item;
    this.receiver_details = item;
    console.log(this.receiver_details);
    console.log(item);
    this.show_receiver = true;
    this.add_receiver = false;
    // var new_receiver = this.receivers.filter(x => x.customer_id === item.customer_id);
    // this.receiver_details = new_receiver[0];
    // console.log(this.receiver_details);
  }

  /**
   * Filter origin check points from check point list
   * @param key 
   * @returns 
   */
  searchOrigin(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchCheckPoints(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.origin_list = res.data;
      // });

      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.origin_list = [];

      this.origin_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.origin_list);
    }
  }
  getOriginDetails(item) {
    this.origin = item;
    this.origin_details = item;
    console.log(this.origin_details);
    console.log(item);
  }

  /**
   * Filter destination list from check point list
   * @param key 
   * @returns 
   */
  searchDestination(key) {
    if (key.which != 13 && key.which != 37 && key.which != 38 && key.which != 39 && key.which != 40) {
      if (key.target.value == null || key.target.value == '') {
        return;
      }
      // this.waybillService.searchCheckPoints(key.target.value).subscribe(res => {
      //   console.log(res);
      //   this.destination_list = res.data;
      // });
      var svalue = key.target.value.toLowerCase();
      console.log(svalue);
      console.log(this.check_points);
      this.destination_list = [];

      this.destination_list = this.check_points.filter(checck_point => checck_point.check_point.toLowerCase().includes(svalue))
      console.log(this.destination_list);
    }
  }

  getDestinationDetails(item) {
    this.destination = item;
    this.destination_details = item;
    console.log(this.destination_details);
    console.log(item);
  }
  displayFn(sender?: Sender): string | undefined {
    return sender ? sender.customer_name : undefined;
  }
  displayFn1(receiver?: Receiver): string | undefined {
    return receiver ? receiver.customer_name : undefined;
  }
  displayFn2(item?: Commodity): string | undefined {
    return item ? item.item_name : undefined;
  }
  displayFn3(origin?: Checkpoints): string | undefined {
    return origin ? origin.check_point : undefined;
  }
  displayFn4(destination?: Checkpoints): string | undefined {
    return destination ? destination.check_point : undefined;
  }
  displayFn5(country?: Countries): string | undefined {
    return country ? country.country_name : undefined;
  }

}
function i(i: any) {
  throw new Error('Function not implemented.');
}

