import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintServiceService {
  isPrinting = false;
  getInstituteDetails: any;

  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: string[]) {

    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }]);
  }

  printSingleDocument(documentName: string, documentData: string) {
    console.log(documentData);
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }]);
  }

  onDataReady(title) {
    setTimeout(() => {
      document.title = title
      // Start set landscape
      // var css = '@page { size: landscape; }',
      //   head = document.head || document.getElementsByTagName('head')[0],
      //   style = document.createElement('style');
      // style.type = 'text/css';
      // style.media = 'print';
      // style.appendChild(document.createTextNode(css));
      // head.appendChild(style);
      // end landscape
      window.print();
      self.close();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }

  printUsingTwoParameter(documentName: string, para_one, para_two) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two]
        }
      }]);
  }

  printUsingThreeParameter(documentName: string, para_one, para_two, para_three) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two, para_three]
        }
      }]);
  }


  printUsingFourParameter(documentName: string, para_one, para_two, para_three, para_four) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, para_one, para_two, para_three, para_four]
        }
      }]);
  }

  /**
   * to print with extra parameters
   */
  printDocumentWithExtraParams(documentName: string, documentData: string, extraParams) {
    console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData]
        }
      }], navigationExtras);
  }

  /**
   * to print with extra parameters and 2 parameters
   */
  printDocumentTwoWithExtraParams(documentName: string, documentData: string, documentData2: string, extraParams) {
    console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData, documentData2]
        }
      }], navigationExtras);
  }

  /**
   * to print with extra parameters and 4 parameters
   */
  printDocumentFourWithExtraParams(documentName: string, documentData: string, documentData2: string, documentData3: string, documentData4: string, extraParams) {
    console.log(documentData);
    this.isPrinting = true;
    const queryParams: any = {};
    queryParams.myArray = JSON.stringify(extraParams);
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData, documentData2, documentData3, documentData4]
        }
      }], navigationExtras);
  }

}
