import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { FormBuilder, Validators, FormGroup,FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StaffService } from 'app/services/staff.service';
interface staff_type {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class RegisterComponent implements OnInit {
  // batches: Batch[];
  SignupFormGroup: FormGroup;
  designation_list: any;
  departments: any;
  staff_types: staff_type[] = [
    { value: 1, viewValue: 'Teaching Staff' },
    { value: 2, viewValue: 'Non Teaching Staff' },
  ];
  signup_disable = false;
  constructor(private formBuilder: FormBuilder, private staffservice: StaffService, private toastr: ToastrService, private datepipe: DatePipe, private router: Router) {}
  ngOnInit() {
    // throw new Error('Method not implemented.');

    this.staffservice.getDesignationLists().subscribe(res => {
      this.designation_list = res.data;
    })
    this.staffservice.getDepartmentList().subscribe(res => {
      this.departments = res.data;
    })
    this.SignupFormGroup = this.formBuilder.group({
      faculty_status:new FormControl(''),
      faculty_leaving_date:new FormControl(''),
      qualification:new FormControl(''),
      faculty_id:new FormControl(''),
      dep_id:new FormControl('', Validators.required),
      faculty_type:new FormControl(''),
      faculty_name:new FormControl('', Validators.required),
      faculty_gender:new FormControl('', Validators.required),
      faculty_join_date:new FormControl('', Validators.required),
      faculty_username:new FormControl('', Validators.required),
      faculty_address:new FormControl('', Validators.required),
      faculty_email: new FormControl('',Validators.email),
      designation_id:new FormControl('', Validators.required),
      faculty_mobile: new FormControl('',Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      // [Validators.required,Validators.minLength(10),Validators.maxLength(12),]
    })
  }
  onSignup() {
    this.signup_disable = true;
    // this.SignupFormGroup.value
    const faculty_join_date = this.datepipe.transform(this.SignupFormGroup.get('faculty_join_date').value, 'yyyy-MM-dd');
    this.SignupFormGroup.patchValue({
      faculty_join_date: faculty_join_date,
    });
    console.log(faculty_join_date)
    console.log(this.SignupFormGroup.value);
    if (this.SignupFormGroup.invalid) {
      this.toastr.info("Enter Valid Details");
      this.signup_disable = false;
    } else {
      console.log(this.SignupFormGroup.value);
      this.staffservice.signUp(this.SignupFormGroup.value).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.router.navigateByUrl('/auth');
          this.toastr.success(res.message);
          this.signup_disable = false;
        }
        else {
          this.toastr.warning(res.message);
          this.signup_disable = false;
        }

      })
    }


  }
  // public socialSignIn(socialPlatform : string) {
  //   let socialPlatformProvider;
  //   if(socialPlatform == "facebook"){
  //     socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
  //   }else if(socialPlatform == "google"){
  //     socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //   } else if (socialPlatform == "linkedin") {
  //     socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
  //   }
    
  //   this.socialAuthService.signIn(socialPlatformProvider).then(
  //     (userData) => {
  //       console.log(socialPlatform+" sign in data : " , userData);
  //       // Now sign-in with userData
  //       // ...
            
  //     }
  //   );
  // }

}
