import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  readonly rootUrl = environment.rootUrl;

  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns 
   */
  getInvoicedTrips() {
    return this.http.get<any>(this.rootUrl + '/trip/get-invoiced-trips');
  }

  /**
   * 
   * @param id 
   */
  getTripDetails(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-details/' + id);
  }

  /**
   * 
   * @param id 
   */
  getTripTrackDetails(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-track-details/' + id);
  }

  /**
 * 
 * @param id 
 */
  getTripRouteDetails(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-route-details/' + id);
  }

  /**
   * 
   * @param id # trip id
   */
  getTripById(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-byId/' + id);
  }

  /**
   * 
   * @param from 
   * @param to 
   */
  getTripHistory(from, to) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-history/' + `${from}/${to}`);
  }


  getTripRoute() {
    return this.http.get<any>(this.rootUrl + '/trip/get-routes');
  }

  /**
 * 
 * @param from 
 * @param to 
 */
  getRecieptReport(from, to) {
    return this.http.get<any>(this.rootUrl + '/finance/get-receipt-report/' + from + '/' + to);
  }

  /**
   * 
   * @returns 
   */
  getLast50Trips() {
    return this.http.get<any>(this.rootUrl + '/trip/get-last-trips');
  }

  /**
   * 
   * @returns 
   */
  getTripMoveStatus() {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-move-status');
  }


  /**
   * 
   * @param today 
   */
  getTripInfo(today) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-info/' + today);
  }

  /**
   * 
   * @param key 
   */
  searchTripByName(key) {
    return this.http.get<any>(this.rootUrl + '/trip/search-trip-by-name/' + key);
  }

  /**
   * 
   * @param data 
   */
  changeTripPosition(data) {
    return this.http.post<any>(this.rootUrl + '/trip/change-trip-position', data);
  }

  /**
   * 
   */
  getTrips() {
    return this.http.get<any>(this.rootUrl + '/trip/get-trips');
  }

  /**
   * NOt verified trips
   */
  getVerificationTrips() {
    return this.http.get<any>(this.rootUrl + '/trip/get-verification-trips');
  }

  getConsignmentTrack(consment_movement_id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-consignment-track/' + consment_movement_id);
  }


  /**
   * 
   */
  getTripsForInvoicing() {
    return this.http.get<any>(this.rootUrl + '/trip/get-trips-for-invoice');
  }

  getMovementAnalysis() {
    return this.http.get<any>(this.rootUrl + '/accounting/get-movement-analysis');
  }

  /**
   * 
   * @param invoice_id 
   * @returns 
   */
  getInvoiceDetails(invoice_id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-invoice-details/' + invoice_id)
  }

  /**
   * 
   * @param customer_id 
   * @returns 
   */
  getCustomerStatements(customer_id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-customer-invoice-statements/' + customer_id)
  }


  /**
   * 
   */
  getLastTripNo() {
    return this.http.get<any>(this.rootUrl + '/trip/get-last-trip-number');
  }

  /**
   * 
   */
  getVehicles() {
    return this.http.get<any>(this.rootUrl + '/trip/get-vehicles');
  }

  getCustomsInvoice(trip_id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-customs-invoice/' + trip_id);
  }

  /**
   * 
   */
  getDrivers() {
    return this.http.get<any>(this.rootUrl + '/trip/get-drivers');
  }

  /**
   * 
   */
  getVehicleDrivers() {
    return this.http.get<any>(this.rootUrl + '/trip/get-vehicle-drivers');
  }

  /**
   * 
   * @param data 
   */
  addVehicle(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-vehicle', data);
  }


  /**
   * 
   * @param data 
   */
  addVehicleDetails(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-vehicle-details', data);
  }

  /**
   * 
   * @param data 
   */
  addDriver(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-driver', data);
  }

  /**
   * 
   * @param data 
   */
  addTrip(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-trip', data);
  }

  addTripMoveStatus(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-trip-move-status', data);
  }

  tripRouteAdd(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-route', data);
  }

  addTripRouteDetails(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-route-details', { data });
  }

  updatetripRouteDetails(data) {
    return this.http.post<any>(this.rootUrl + '/trip/update-route-details', { data });
  }

  /**
   * 
   */
  getAllCheckPoints() {
    return this.http.get<any>(this.rootUrl + '/trip/get-all-check-points');
  }

  /**
   * 
   * @param data 
   */
  addCheckPoint(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-check-point', data);
  }

  /**
   * 
   * @param data 
   */
  editCheckPoint(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-check-point', data);
  }
  editVehicle(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-Vehicle', { data });
  }

  editDriver(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-driver', { data });
  }

  editTripMoveStatus(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-trip-move-status', { data });
  }

  tripRouteEdit(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-route', { data });
  }

  /**
   * 
   * @param id 
   */
  getCheckList(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-check-list/' + id);
  }

  /**
   * 
   * @param trip_id 
   */
  getTripWayBills(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-way-bills/' + id);
  }

  /**
   * 
   * @param trip_id 
   */
  getTripWayBillsinvoice(id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-way-bills-invoice/' + id);
  }

  /**
   * 
   * @param trip_id
   * @param receiver_id
   */
  getTripWayBillsForInvoice(trip_id, receiver_id) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-way-bills-for-invoice/' + trip_id + '/' + receiver_id);
  }


  /**
   * 
   * @param invoice_data 
   */
  addInvoices(data) {
    return this.http.post<any>(this.rootUrl + '/trip/add-invoice', data);
  }

  /**
   * 
   * @param invoice_data 
   */
  editInvoices(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-invoice', data);
  }

  editTrip(data) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-trip', { data });
  }

  /**
   * 
   * @param trip_id
   * @param receiver_id
   */
  getReturnedWayBills() {
    return this.http.get<any>(this.rootUrl + '/waybill/get-returned-way-bills');
  }

  /**
   * 
   * @param data 
   * @param id 
   * @param narration 
   */
  addPackageToTrip(data, id, narration) {
    return this.http.post<any>(this.rootUrl + '/trip/add-package-to-trip', { data, id, narration });
  }

  /**
   * 
   * @param data 
   * @param id 
   * @param narration 
   */
  addPackageToReturn(data, id, narration) {
    return this.http.post<any>(this.rootUrl + '/trip/add-package-to-return', { data, id, narration });
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  editManifesto(data, narration, prev_narration, waybill_id) {
    return this.http.post<any>(this.rootUrl + '/trip/edit-manifesto', { data, narration, prev_narration, waybill_id });
  }

  /**
   * 
   * @param date 
   */
  getTripStatistics(date) {
    return this.http.get<any>(this.rootUrl + '/trip/get-trip-statistics/' + date);
  }

  /**
  * 
  * @param trip_id 
  */
  verifyCheckList(trip_id, need_subledger, subledger) {
    return this.http.post<any>(this.rootUrl + '/trip/verify-check-list', { trip_id, need_subledger, subledger: subledger });
  }

  /**
 * 
 * @param details 
 */
  changeBillTo(details) {
    return this.http.post<any>(this.rootUrl + '/trip/change-billto', { details });
  }
}
