<!-- <div class="main-content"> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h2 class="font-weight-bold text-center head">GOLDEN ELITE CARGO
        {{ind_trip? ind_trip?.trip_no+' -'+ ind_trip?.trip_name:''}}
        {{ind_trip?.date|date:'dd/MM/yyyy'}} JOB NO:{{ind_trip? ind_trip?.job_no + ' ':''}} </h2>
       
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table table-bordered" id="checkList" style="line-height: 85%;">
        <thead class="headColor text-light">
          <tr>
            <th>Sl</th>
            <th>Code</th>
            <th>Way Bill</th>
            <th>Receiver Name</th>
            <th *ngFor="let item of [].constructor(10); let i = index">{{i+1}}</th>
            <th class="text-right">Total</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody class="bodyColor">
          <tr *ngFor="let dat of trip_details;index as i">
            <td>{{i+1}}</td>
            <td>{{dat?.receiver_code | uppercase}}</td>
            <td>{{dat?.waybill_list}}</td>
            <td>{{dat?.receiver_name}}</td>
            <td *ngFor="let item of [].constructor(10); let i = index">
              {{dat?.loaded_array[i]?dat?.loaded_array[i]:''}}</td>
            <!-- <td class="text-right">{{dat?.total_quantity}}</td> -->
            <td class="text-right">{{dat?.total_loaded_pkg}}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="14" class="text-right">Total</td>
            <!-- <td class="text-right">{{total_quantity}}</td> -->
            <td class="text-right">{{total_loaded_pkg}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- </div> -->